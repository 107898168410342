@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 24px;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.title {
  composes: h2 from global;
  color: var(--marketplaceColor);
}

.description {
  composes: h4 from global;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
}
